.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: fadeUp 0.5s ease-in-out forwards;
    opacity: 0;
    z-index: 2 !important;
    background-color: #295ed594;
}

.modalfullscreen .modal-dialog {
    max-width: 100%;
    height: 100vh;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 0px;
}

.modalfullscreen .map-style {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0px;
    left: 0;
    z-index: 0;
    align-items: center;
}

.modalheader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 20px;
    z-index: 1;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

span.wooflogo {
    width: 130px;
    height: 34px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

/* .map-style {
    position: absolute;
    top: 190px;
    left: 0;
    z-index: 1001;
    height: 500px;
    width: 100%;
    align-items: center;
} */

.map-input-style {
    position: absolute;
    top: 30%;
    left: 0;
    z-index: 1001;
    width: 100%;
    padding: 15px;
    gap: 5px;
}

@media (max-width: 500px) {

    .map-input-style {
        top: 25%;
    }
}

.map-input-style p {
    font-size: 15px;
    color: #000832;
    text-align: center;
}

.map-input-style h3 {
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    margin-top: 45px;
}

.map-input-style textarea {
    border-radius: 7px;
    padding: 15px 0px 0px 20px;
    outline: none;
    border: 1px solid #00083280;
    ;
}

.inner-map-modal-main {
    background: white;
    position: fixed;
    top: calc(100vh - 290px);
    max-width: 90%;
    width: 100%;
    padding: 20px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.cross-icon {
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
    filter: drop-shadow(1px 1px 1px black);
}

img.image-logo+div {
    width: calc(100% - 76px);
}

.cross-icon:active {
    scale: 0.9;
}


.inner-map-modal-main p {
    font-size: 15px;
    color: #000832;
    margin: 0;
}

.inner-map-modal-main h3 {
    font-size: 20px;
    font-weight: bold;
    margin: 0;
}


.image-logo {
    width: 60px;
    height: 60px;
    object-fit: cover;
    border-radius: 50%;
    cursor: pointer;
    border: 3px solid #408de9;
}

.red-border {
    border: 1px solid red;
}

.validationError {
    color: red !important;
}

.loader-main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: rgb(205 227 243 / 50%);
}

.loader-main p {
    margin: 0;
    font-size: 20px;
    font-weight: 600;
}

/* zoom-in zoom-out */
.gm-bundled-control.gm-bundled-control-on-bottom {
    display: none;
}

.gm-bundled-control.gm-bundled-control-on-top {
    display: none;
}

/* Hide the expand button */
.gm-fullscreen-control {
    display: none !important;
}

.back-button {
    position: absolute;
    left: 10px;
    top: 90px;
    cursor: pointer;
}

.back-button:active {
    scale: 0.9;
}

@keyframes fadeUp {
    0% {
        transform: translateY(5px);
        opacity: 0;
    }

    100% {
        transform: translateX(0);
        /* Bring the modal up to its original position */
        opacity: 1;
    }
}