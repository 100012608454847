.protect-page-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    background: rgba(84, 167, 220, 100%);
    position: relative;
}

.main-protectPage {
    background-color: rgba(84, 167, 220, 100%);
    display: flex;
    align-items: center;
    justify-content: center;
}

.bottom-inner-content h2 {
    font-family: "Octarine";
    font-size: 25px;
    font-weight: 700;
    margin: 0;
}

.bottom-inner-content p {
    margin: 0;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 300;
}

.bottom-div {
    position: absolute;
    bottom: 0;
    max-width: 680px;
    width: 100%;
    background-color: white;
    display: flex;
    box-shadow: -4px -4px 10px 0px #DADFEA40;
    justify-content: space-around;
    padding: 30px;
    align-items: center;
    border-radius: 10px 10px 0px 0px;
}

.bottom-inner-img {
    background: url("../../../public/assets/images/cart-bg.svg");
    background-repeat: no-repeat;
    background-size: contain;
    width: 75px;
    height: 75px;
    display: flex;
    justify-content: center;
}

.inner-main {
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    background: #ffffff;
    padding: 10px;
    box-shadow: 0px 0px 0px 2px #E8ECF3;
    position: relative;
    align-items: center;
    gap: 15px;
}

.rounded-image {
    background-image: url("../../../public/assets/images/rounded.svg");
    width: 150px;
    height: 150px;
    background-size: cover;
    display: flex;
    justify-content: center;
    margin-top: -60px;
    align-items: center;
}

.inner-main img {
    width: 90px;
    height: 90px;
}

.inner-main h3 {
    font-weight: 700;
    font-family: 'Poppins';
    margin: 0;
    text-transform: capitalize;
    width: 100%;
    padding: 0px 30px;
    text-align: center;
}

.inner-main p {
    font-weight: 400;
    font-family: 'Poppins';
    width: 100%;
    padding: 0px 40px;
    font-size: 18px;
    text-align: center;
}

@media (max-width:700px) {
    .main-protectPage {
        padding: 0px 25px;
    }

    .rounded-image {
        width: 100px;
        height: 100px;
    }

    .inner-main {
        width: 100%;
    }

    .inner-main img {
        width: 42px;
        height: 42px;
    }

    .bottom-div {
        border-radius: 0px 0px 0px 0px;
    }

}